<!--
 * @Author: Yran
 * @Date: 2021-11-09 18:44:36
 * @LastEditors: LiuXin
 * @LastEditTime: 2022-01-05 18:10:59
-->
<template>
  <div class="clyq_advertise_table popularize-advertise-table" id="clyq-advertise-table" ref="tableWrapper">
    <table-with-statistic :columns="columns" :statistic="statistic" v-bind="table" :data-source="dataSource" :loading="loading" :scroll="{ x: columns.length * 150, wrapperId: 'clyq-advertise-table', otherHeight: 40 }" :row-selection="rowSelection" @change="sortAndPageChange" rowkey="campaign_id">
      <template #default="{ record, text, index, column }">
        <!-- 开关 -->
        <template v-if="column.dataIndex == 'switch'">
          <a-switch :loading="record.switchLoading" :checked="switchStatus(record, 'status')" :disabled="switchStatus(record, 'disabled')" size="small" @change="(changed, e) => switchChange(changed, e, record)" />
        </template>

        <!-- 操作 -->
        <template v-else-if="column.dataIndex == 'operate'">
          <span v-if="activeKey == 'product' && record.product_name == '未绑定产品'" style="color: #999">详情</span>
          <a v-else @click.stop="tableRowOperate('detail', record)"> 详情</a>
          <!-- <span class="cursorPoi" style="color: #2f70f4; padding: 0 24px 0 12px" @click="openHostDrawer(record)">自定义托管</span> -->
          <!-- <div class="hosting-status-icon">
                <svg-icon :icon-class="record.hostStatus ? 'hosting_icon' : 'unmanaged_icon'" class-name="status-icon"></svg-icon>
          </div> -->
        </template>

        <!-- 广告状态 -->
        <template v-else-if="column.dataIndex == 'unit_status'">
          {{ showUnitStatus(record) }}
          <div class="status_wrapper flexAlignCenter">
            <a-tooltip class="mr10">
              <template #title>{{ record.learn_status }}</template>
              <span class="status_box" v-if="record.learn_status && record.learn_status != '0'" :class="setLearnStatusClass('learnStatus', record.learn_status)">
                <CheckCircleOutlined v-if="setLearnStatusClass('learnStatus', record.learn_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                学
              </span>
            </a-tooltip>
            <a-tooltip>
              <template #title>{{ record.compensate_status }}</template>
              <span class="status_box" v-if="record.compensate_status && record.compensate_status != '不需要赔付' && record.compensate_status != '0'" :class="setLearnStatusClass('compensateStatus', record.compensate_status)">
                <SafetyOutlined v-if="setLearnStatusClass('compensateStatus', record.compensate_status) != 'status_box_blue'" class="status_box_icon" />
                <ClockCircleOutlined v-else class="status_box_icon" />
                保
              </span>
            </a-tooltip>
          </div>
        </template>

        <!-- 可跳转到其他tab的且可以编辑列 -->
        <template v-else-if="columnShow(column) == 'jumpable&Editable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }">
            <template #default>
              <a-tooltip v-if="column.title.includes('名称')" arrow-point-at-center>
                <template #title>{{ text }}</template>
                <a @click.stop="toNextTab(record)" :class="{ 'textHide-2rows': column.title.includes('名称') }">{{ text }}</a>
              </a-tooltip>
              <a v-else @click.stop="toNextTab(record)">{{ text }}</a>
            </template>
          </editable-column>
        </template>

        <!--仅可跳转的列 -->
        <template v-else-if="columnShow(column) == 'onlyJumpable'">
          <a @click.stop="toNextTab(record)">{{ text }}</a>
          <p v-if="activeKey == 'account'">ID：{{ record.advertiser_id }}</p>
        </template>

        <!-- 仅可以编辑 -->
        <template v-else-if="columnShow(column) == 'onlyEditable'">
          <editable-column v-bind="{ record, text, index, column, activeKey, editable: !switchStatus(record, 'disabled') }"> </editable-column>
        </template>

        <!-- 创意图 -->
        <template v-else-if="column.dataIndex == 'creativity'">
          <div class="flexAlignCenter">
            <div v-if="record.type == 'customer'" style="margin-right: 8px" :class="(column.width = 250)">
              <img v-if="record.photo_id == '0'" :src="record.imageTokens?.[0]" :alt="record.ad_name" style="width: 80px; height: 45px; object-fit: scale-down" />
              <video v-else controls :src="record.url" style="width: 80px; height: 45px; object-fit: scale-down"></video>
            </div>
            <p class="textHide-2rows" :title="record.creative_name">{{ record.creative_name || '-' }}</p>
          </div>
        </template>

        <!-- 广告出价 -->
        <template v-else-if="column.dataIndex == 'unit_bids'">
          {{ setBidColumn(record) }}
        </template>
      </template>
    </table-with-statistic>
  </div>
  <!-- 详情抽屉 -->
  <detail-drawer v-model:visible="drawerVisible" :row-data="rowData"></detail-drawer>
  <!-- 自定义托管抽屉 -->
  <host-drawer v-model:visible="hostDrawerVisible" :row-data="rowData" channel="KS"></host-drawer>
</template>

<script>
import DetailDrawer from '../detail/index.vue';
import HostDrawer from '@/views/popularize/components/HostDrawer.vue';
import { getTableDataKS } from '@/api/popularize/clyq/index.js';
import EditableColumn from './EditableColumn.vue';
import TableWithStatistic from '@/components/TableWithStatistic/index.vue';
import { SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons-vue';
import { columnMap } from '@/utils/utils.js';
import columns from '@/views/popularize/assets/clyqColumns.js';
import moment from 'moment';
export default {
  name: 'ClyqTabsTable',
  components: { DetailDrawer, EditableColumn, SafetyOutlined, CheckCircleOutlined, ClockCircleOutlined, HostDrawer, TableWithStatistic },
  props: {
    columns: { type: Array, default: () => [] },
    selectedRows: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    activeKey: { type: String, default: 'product' },
  },
  provide() {
    return { getTabActiveKey: () => this.activeKey, getMediaAccount: 'CLYQ', tableData: this.tableData };
  },
  inject: ['getBaseFilter', 'clyqDict', 'getCreativityType'],
  emits: ['update:selectedRows', 'update:loading', 'switchChange', 'update:activeKey', 'updateFreshTime'],
  data() {
    return {
      selectedRowKeys: [], //当前选中行id
      rowData: {}, //点击详情的所在的行数据
      drawerVisible: false, //抽屉是否打开
      hostDrawerVisible: false, //自定义托管抽屉是否打开
      dataSource: [], //表格数据
      table: {
        bordered: true,
        size: 'small',
        scroll: { x: 'max-content' },
        showExpanded: false,
        pagination: { current: 1, pageSize: 10, total: 0, showTotal: total => `总计${total}条`, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '50'] },
      },
      baseFilters: {},
      bidDictionary: columns.bidDictionary,
      statistic: {},
    };
  },
  computed: {
    // 表格可选择配置
    rowSelection() {
      if (this.activeKey == 'product') {
        return null;
      }
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          this.$emit('update:selectedRows', selectedRows);
        },
        getCheckboxProps: record => {
          return {
            // 状态是已删除的行不可选择
            disabled: this.switchStatus(record, 'disabled'),
          };
        },
      };
    },

    // 启停状态
    switchStatus() {
      // mode: disabled:是否可编辑   status：状态
      // 1投放、2-暂停、3-删除
      return (record, mode) => {
        if (mode == 'disabled') {
          return record.put_status == 3;
        } else if (mode == 'status') {
          return record.put_status == 1;
        }
      };
    },
  },
  methods: {
    /**
     * @description: 打开自定义托管侧边栏
     * @param {*} record 选中的行数据
     * @return {*}
     */
    openHostDrawer(record) {
      this.drawerVisible = false;
      this.hostDrawerVisible = true;
      this.rowData = record;
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt && returnOpt[text];
      }
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */
    setBidColumn(record) {
      const cjList = record.unit_bids?.split('|');
      if (cjList && cjList.length > 0) {
        const bidType = this.columnMap(this.bidDictionary.bid_type, cjList[0]);
        const bidNumber = cjList[1] == 0 ? cjList[2] : cjList[1];
        const ocpxActionType = this.columnMap(this.bidDictionary.ocpx_action_type, cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == '1') {
          return `${bidType || '-'} 自动出价${ocpxActionType ? '/' : ''}${ocpxActionType}` != '' ? `${bidType || '-'} 自动出价${ocpxActionType ? '/' : ''}${ocpxActionType}` : '-';
        } else {
          const bidShow = `${bidType || ''} ${bidNumber || 0} 元${ocpxActionType ? '/' : ''}${ocpxActionType}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
    /**
     * @description: 设置状态icon类名
     * @param {*} type 状态类别
     * @param {*} status 状态
     * @return {*}
     */
    setLearnStatusClass(type, status) {
      const list = {
        learnStatus: {
          学习失败: 'status_box_grey',
          学习成功: 'status_box_green',
          学习中: 'status_box_blue',
        },
        compensateStatus: {
          已赔付完成: 'status_box_grey',
          已失效: 'status_box_grey',
          成本保障生效中: 'status_box_green',
          成本保证确认中: 'status_box_blue',
        },
      };
      return list[type]?.[status] || 'status_box_grey';
    },
    /** 設置計劃顯示
     * @description:
     * @param {*} record
     * @return {*}
     */
    showUnitStatus(record) {
      if (record.unit_status) {
        const statusList = record.unit_status.split(':');
        const unitStatusOptions = [
          { value: -1, label: '不限' },
          { value: 1, label: '计划已暂停' },
          { value: 3, label: '计划超预算' },
          { value: 6, label: '余额不足' },
          { value: 11, label: '审核中' },
          { value: 12, label: '审核未通过' },
          { value: 14, label: '已结束' },
          { value: 15, label: '已暂停' },
          { value: 17, label: '组超预算' },
          { value: 19, label: '未达投放时间' },
          { value: 20, label: '有效' },
          { value: 22, label: '不在投放时段' },
        ];
        const studyStatusOptions = [
          { value: 1, label: '学习中' },
          { value: 2, label: '学习成功' },
          { value: 3, label: '学习失败' },
        ];
        const compensateStatusOptions = [
          { value: 0, label: '不需要赔付' },
          { value: 1, label: '成本保障生效中' },
          { value: 2, label: '成本保证确认中' },
          { value: 3, label: '已赔付完成' },
          { value: 4, label: '已失效' },
        ];
        record.learn_status = columnMap(studyStatusOptions, statusList[1], 'value', 'label') || statusList[1] || null;
        record.compensate_status = columnMap(compensateStatusOptions, statusList[2], 'value', 'label') || statusList[2] || null;
        return columnMap(unitStatusOptions, statusList[0], 'value', 'label') || statusList[0] || '-';
      } else {
        return '-';
      }
    },
    // 获取表格数据 filters：筛选条件，sorterFilters：排序条件
    async getTableData({ filters, sorterFilters }) {
      this.drawerVisible = false;
      this.$emit('update:loading', true); //tableLoading开始
      //生成请求参数
      const getParams = () => {
        if (filters) {
          this.activeKey == 'creativity' && (filters.unitId = filters.unit);
          this.baseFilters = { ...filters };
        }
        // 排序数据 （产品和账户默认降序）
        if (sorterFilters) {
          this.baseFilters.order = sorterFilters.order;
          this.baseFilters.orderField = sorterFilters.orderField;
        } else if (['product', 'account'].includes(this.activeKey)) {
          this.baseFilters.order = 'desc';
          this.baseFilters.orderField = 'charge';
        } else {
          this.baseFilters.order = undefined;
          this.baseFilters.orderField = undefined;
        }
        let isNotData = this.baseFilters.isNotData ? 1 : 0;
        const { current: page, pageSize: limit } = this.table.pagination;
        const elseParams = {
          isNotData: isNotData,
          page,
          limit: limit,
          tempName: this.baseFilters.tempName || '自定义列表', //模板
          userId: this.$store.state.oauth.userInfo.userId,
          type: this.getCreativityType(),
        };
        const params = { ...this.baseFilters, ...elseParams, mediaChannel: 'KS' };
        return params;
      };
      const params = getParams();
      try {
        const res = await getTableDataKS(params, this.activeKey);
        const data = res.data?.list || [];
        const rowKeyDict = {
          product: 'key',
          account: 'advertiser_id',
          campaign: 'campaign_id',
          ad: 'unit_id',
          creativity: 'creative_id',
        };
        const rowkey = rowKeyDict[this.activeKey];
        const statistics = res.data?.statistics || {};
        if (res.data?.statistics?.data_time) {
          const time = moment(res.data.statistics.data_time).format('YYYY-MM-DD hh:mm:ss');
          this.$emit('updateFreshTime', time);
        }

        const columnsDataIndexs = this.columns.map(column => column.dataIndex);
        columnsDataIndexs.forEach(key => (statistics[key] = statistics[key] || '-'));
        const key = this.columns.find(column => column.dataIndex != 'switch')?.dataIndex;
        statistics[key] = `总计：${res.data?.totalCount || 0}`;
        this.statistic = statistics;

        data.forEach((item, index) => {
          // 广告投放位置
          if (item.scene_id) {
            try {
              const list = JSON.parse(item.scene_id);
              item.scene_id = list.map(id => this.clyqDict.sceneId(id)).join('、');
            } catch (error) {
              console.log(error);
            }
          }
          // 设置深度出价
          if (item.deep_conversion_bid) {
            const list = item.deep_conversion_bid.split('|');
            let price = list[0];
            if (price == 0) {
              price = '不限';
            } else if (list[1]) {
              const type = this.clyqDict.deepConversionType(list[1]);
              price = price + ' 元/' + type;
            }
            item.deep_conversion_bid = price;
          }
          // 计划状态
          if (item.campaign_status) {
            item.campaign_status = this.clyqDict.campaignStatus(item.campaign_status);
          }
          // 创意状态
          if (item.creative_status) {
            item.creative_status = this.clyqDict.creativityStatus(item.creative_status);
          }
          // 推广目的
          if (item.landing_type) {
            item.landing_type = this.clyqDict.landingType(item.landing_type);
          }
          // 广告组预算
          if (item.unit_budget == 0) {
            item.unit_budget = '不限';
          }
          // 计划预算
          if (item.compaign_budget == 0) {
            item.compaign_budget = '不限';
          }
          // 账户预算
          if (item.bugdet == 0) {
            item.bugdet = '不限';
          }
          item.key = item[rowkey] || this.$UUID.v1();
        });
        this.dataSource = data;
        this.table.pagination.total = res.data?.totalCount || 0;
      } catch (err) {
        console.log(err);
      } finally {
        this.$emit('update:loading', false);
      }
    },
    // 表格分页和排序变化
    sortAndPageChange(pagination, filters, sorter) {
      // 除非更改页数，其他的筛选条件变化都会将当前页重置为1
      if (this.table.pagination.current != pagination.current) {
        this.table.pagination.current = pagination.current;
      } else {
        this.table.pagination.current = 1;
      }
      this.table.pagination.pageSize = pagination.pageSize;
      const sorterDict = { ascend: 'asc', descend: 'desc' };
      let sorterFilters = {
        order: this.baseFilters.order,
        orderField: this.baseFilters.orderField,
      };
      if (sorter.order) {
        sorterFilters = { orderField: sorter.field, order: sorterDict[sorter.order] };
      }
      this.getTableData({ sorterFilters: sorterFilters });
    },
    // 表格行操作(type:detail/edit)
    tableRowOperate(type, rowData) {
      this.rowData = rowData;
      if (type == 'detail' && this.drawerVisible) {
        //
      } else if (!this.drawerVisible) {
        this.hostDrawerVisible = false;
        this.drawerVisible = true;
      }
    },
    // 启停
    switchChange(changed, e, record) {
      this.$emit('switchChange', changed, record);
    },
    // 跳转到下一个tab页
    toNextTab(record) {
      const tabs = ['product', 'account', 'campaign', 'ad', 'creativity'];
      const nextTab = tabs[tabs.indexOf(this.activeKey) + 1];
      this.$emit('update:activeKey', nextTab);
      const filterOptions = this.getBaseFilter();
      this.selectedRowKeys = [];
      switch (nextTab) {
        case 'account':
          filterOptions.productCodes = record.productCode;
          filterOptions.filterName && !filterOptions.productCodes && (filterOptions.productName = record.product_name);
          break;
        case 'campaign':
          filterOptions.filterName = 'advertiser';
          filterOptions[filterOptions.filterName] = record.advertiser_id;
          break;
        case 'ad':
          filterOptions.filterName = 'campaign';
          filterOptions[filterOptions.filterName] = record.campaign_id;
          break;
        case 'creativity':
          filterOptions.filterName = 'unit';
          filterOptions[filterOptions.filterName] = record.unit_id;
          break;
        default:
          break;
      }
    },
    // 控制列是否展示
    columnShow(column, record) {
      // 可以跳转到其他tab的列
      let jumpableColumn = { product: 'product_name', account: 'advertiser_name', campaign: 'campaign_name', ad: 'unit_name' };
      // 可编辑列
      let editableColumn = {
        account: ['bugdet', 'remark'],
        campaign: ['campaign_name', 'compaign_budget'],
        ad: ['unit_name', 'unit_bids', 'deep_conversion_bid', 'unit_budget'],
      };
      let flag = 'else';
      if (column.dataIndex == jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'jumpable&Editable'; //可跳转且可编辑
        column.width = '20em';
      } else if (column.dataIndex == jumpableColumn[this.activeKey] && !editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        column.width = '20em';
        flag = 'onlyJumpable'; // 可跳转但不能编辑
      } else if (column.dataIndex != jumpableColumn[this.activeKey] && editableColumn[this.activeKey]?.includes(column.dataIndex)) {
        flag = 'onlyEditable'; // 不可跳转但能编辑
      } else if (column.dataIndex == 'advertiser_id' && this.activeKey == 'account') {
        flag = '';
      }
      return flag;
    },
  },
};
</script>

