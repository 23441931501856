<!--
 * @Author: LiuXin
 * @Date: 2021-10-18 11:45:52
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-28 14:07:09
-->
<template>
  <div class="base_info_wrapper w100 h100 flow_auto">
    <div class="base_info w100">
      <!-- 创意 -->
      <div class="img_wrapper">
        <creative-box v-if="baseInfo.url" v-bind="{ imgInfo: baseInfo, status: clyqDict.creativityStatus(baseInfo.status), link: { url: getMaterialDetailUrl(baseInfo), text: '素材详情>>' } }" />
        <a-empty v-else></a-empty>
      </div>

      <!-- 审核信息 -->
      <div class="check_opinion h100">
        <p class="check_title">审核意见</p>
        <div class="sucess_result check_result" v-if="baseInfo.status == 'NORMAL'">
          <span class="result_text">
            <svg-icon icon-class="success" class-name="result_icon"></svg-icon>
            审核通过
          </span>
        </div>
        <div class="fail_result check_result" v-else>
          <p class="result_text">
            <svg-icon icon-class="fail" class-name="result_icon"></svg-icon>
            审核失败
          </p>
          <p class="fail_reason" :title="baseInfo.reviewDetail">{{ baseInfo.reviewDetail }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
export default {
  name: 'CustomerCreativity',
  components: { CreativeBox },
  props: {
    baseInfo: { type: Object, default: () => {} },
  },
  inject: ['clyqDict', 'getBaseFilter', 'mediaChannel'],
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsKS') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      siteDict,
    };
  },
  computed: {
    imgList() {
      const imgList = [{ title: this.baseInfo.title, url: this.baseInfo.videoId, status: this.clyqDict.creativityStatus(this.baseInfo.status) }];
      return imgList;
    },
  },
  methods: {
    getMaterialDetailUrl(item) {
      if (!item.md5) {
        return null;
      }
      let filterCondition = this.getBaseFilter();
      let url = `?md5=${item.md5}&channel=${this.mediaChannel}&sd=${filterCondition.uploadTime[0].format('YYYY-MM-DD')}&ed=${filterCondition.uploadTime[1].format('YYYY-MM-DD')}`;
      if (item.type == 'image') {
        return '/asset/material/image' + url;
      } else {
        return '/asset/material/video' + url;
      }
    },
  },
};
</script>

<style scoped lang="less">
.base_info_wrapper {
  padding: 10px;
  @border: 1px solid rgb(239, 241, 246);
  @radius: 4px;
  .base_info {
    display: flex;
    height: 236px;
    border: @border;
    border-radius: @radius;
    .img_wrapper {
      width: 275px;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .check_opinion {
      flex-grow: 1;
      background: rgb(251, 251, 251);
      margin-left: 10px;
      .check_title {
        height: 14px;
        line-height: 14px;
        font-weight: bold;
        font-size: 14px;
        margin-top: 15px;
        &::before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 12px;
          background: #2f70f4;
          transform: translateY(1px);
          margin-right: 10px;
        }
      }
      .check_result {
        height: calc(100% - 28px);
        border-bottom: @border;
        .result_text {
          padding-left: 15px;
          padding-top: 17px;
        }
        &.sucess_result .result_text {
          color: #23bb67;
        }
        &.fail_result {
          .result_text {
            color: #ec4d4d;
          }
          .fail_reason {
            margin-top: 12px;
            margin-left: 15px;
          }
        }
        .result_icon {
          transform: translateY(2px);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
