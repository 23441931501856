<!--
 * @Author: LiuXin
 * @Date: 2021-10-18 11:45:52
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-28 15:20:01
-->
<template>
  <!-- 691786955 -->
  <div class="base_info_wrapper program-creativity w100 h100 flow_auto">
    <div class="creativity-list">
      <div class="base_info" v-for="(creativity, index) in baseInfo.photoList" :key="index">
        <!-- 创意 -->
        <div class="img_wrapper">
          <creative-box v-if="creativity.url" v-bind="{ imgInfo: creativity, status: clyqDict.creativityStatus(baseInfo.viewStatus), link: { url: getMaterialDetailUrl(creativity), text: '素材详情>>' } }" />
          <a-empty v-else></a-empty>
        </div>

        <!-- 审核信息 -->
        <div class="check_opinion h100">
          <p class="check_title">审核意见</p>
          <div class="sucess_result check_result" v-if="baseInfo.status == 'NORMAL'">
            <span class="result_text">
              <svg-icon icon-class="success" class-name="result_icon"></svg-icon>
              审核通过
            </span>
          </div>
          <div class="fail_result check_result" v-else>
            <p class="result_text">
              <svg-icon icon-class="fail" class-name="result_icon"></svg-icon>
              审核失败
            </p>
            <p class="fail_reason" :title="baseInfo.reviewDetail">{{ baseInfo.reviewDetail }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
export default {
  name: 'ProgramCreativity',
  components: { CreativeBox },
  props: {
    baseInfo: { type: Object, default: () => {} },
  },
  inject: ['clyqDict', 'getBaseFilter', 'mediaChannel'],
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsKS') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      siteDict,
    };
  },
  computed: {
    imgList() {
      const imgList = [{ title: this.baseInfo.title, url: this.baseInfo.videoId, status: this.clyqDict.creativityStatus(this.baseInfo.status) }];
      return imgList;
    },
  },
  methods: {
    getMaterialDetailUrl(item) {
      if (!item.md5) {
        return null;
      }
      let filterCondition = this.getBaseFilter();
      let url = `?md5=${item.md5}&channel=${this.mediaChannel}&sd=${filterCondition.uploadTime[0].format('YYYY-MM-DD')}&ed=${filterCondition.uploadTime[1].format('YYYY-MM-DD')}`;
      if (item.type == 'image') {
        return '/asset/material/image' + url;
      } else {
        return '/asset/material/video' + url;
      }
    },
  },
};
</script>

<style scoped lang="less">
.base_info_wrapper {
  padding: 14px;
  @border: 1px solid rgb(239, 241, 246);
  @radius: 8px;
  overflow: auto;
  .creativity-list {
    display: flex;
    flex-wrap: wrap;
    .base_info {
      padding: 0;
      overflow: hidden;
      display: flex;
      height: 236px;
      border: @border;
      border-radius: @radius;
      width: 50%;
      flex: 1;
      min-width: 40%;
      max-width: 50%;
      &:nth-child(2n) {
        margin-left: 14px;
        margin-bottom: 14px;
        max-width: calc(50% - 14px);
      }
      .img_wrapper {
        width: 295px;
        height: 100%;
        padding: 12px 11px 10px 12px;
        box-sizing: border-box;
        :deep(.creative_box),
        :deep(.ant-empty) {
          width: 100%;
          height: 100%;
          border: 1px solid #e6e9f1;
          border-radius: 8px;
        }
      }
      .check_opinion {
        flex-grow: 1;
        background: rgb(251, 251, 251);
        .check_title {
          height: 14px;
          line-height: 14px;
          font-weight: bold;
          font-size: 14px;
          margin-top: 15px;
          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 12px;
            background: #2f70f4;
            transform: translateY(1px);
            margin-right: 10px;
          }
        }
        .check_result {
          height: calc(100% - 28px);
          border-bottom: @border;
          .result_text {
            padding-left: 15px;
            padding-top: 17px;
          }
          &.sucess_result .result_text {
            color: #23bb67;
          }
          &.fail_result {
            .result_text {
              color: #ec4d4d;
            }
            .fail_reason {
              margin-top: 12px;
              margin-left: 15px;
            }
          }
          .result_icon {
            transform: translateY(2px);
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>
