/*
 * @Author: LiuXin
 * @Date: 2021-10-21 10:51:46
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-27 15:06:25
 */

import axios, { advert_prefix } from '@/utils/axios';

const apiUrl = {
  // 获取基础信息
  getBaseInfo: {
    product: '/ks/productInfo/get?productId=', //产品
    account: '/ks/advertiserInfo/get?advertiserId=', //账户
    ad: '/ks/unit/', //广告组
    campaign: '/ks/campaign/detail/', //计划
    PROGRAM: '/ks/program/creative/', //程序化创意
    CUSTOMER: '/ks/customer/creative/', //自定义创意
  },
  // 获取操作日志
  getOperationLog: {
    account: '', //账户
    advertise: '', //广告
    manage: '', //推广计划
    creativity: '', //创意
  },
};

// 拼接get请求
function getUrl(url, params) {
  let str = '';
  Object.keys(params)?.forEach(key => {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  });
  return url + str.replace('&', '?');
}

// 获取基础信息
export function getBaseInfo(params) {
  let url;
  if (params.type == 'creativity') {
    url = advert_prefix + apiUrl.getBaseInfo[params.creativityType] + params.id;
  } else {
    url = advert_prefix + apiUrl.getBaseInfo[params.type] + params.id;
  }
  return axios.get(url);
}

// 获取操作日志
export function getOperationLog(params) {
  let url = apiUrl.getOperationLog[params.type];
  delete params.type;
  return axios.get(getUrl(url, params));
}
