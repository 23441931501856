<!--
 * @Author: LiuXin
 * @Date: 2021-10-30 18:15:28
 * @LastEditors: Yran
 * @LastEditTime: 2021-12-23 10:19:04
-->
<template>
  <!-- 可以编辑的列 -->
  <a-popover :title="`修改${column.title}`" trigger="click" :visible="visible">
    <template #content>
      <!-- 名称编辑 -->
      <template v-if="column.title.indexOf('名称') > -1 || column.title.indexOf('备注') > -1">
        <a-textarea v-model:value="text" show-count :maxlength="60" style="width: 240px" />
      </template>
      <template v-else-if="column.title.indexOf('预算') > -1">
        <div class="editBudget">
          <a-radio-group v-model:value="updateBudgetMode" class="mb20">
            <a-radio value="BUDGET_MODE_INFINITE">不限</a-radio>
            <a-radio value="BUDGET_MODE_DAY">自定义</a-radio>
            <a-radio value="BUDGET_MODE_SCHEDULE">分日预算</a-radio>
          </a-radio-group>
          <div v-show="updateBudgetMode == 'BUDGET_MODE_DAY'" class="mb10">
            <a-input v-model:value="text" suffix="元/天" placeholder="请输入日预算"></a-input>
            <p class="suggestion">预算最低设置不能低于1000元，不超过9999999.99元</p>
          </div>
          <!-- 分日预算下拉框 -->
          <a-input-group v-if="updateBudgetMode == 'BUDGET_MODE_SCHEDULE'" compact class="edit_by_day_select">
            <div @mousedown="e => e.preventDefault()">
              <a-select style="width: 300px" :placeholder="budgetByDay.total">
                <template #dropdownRender>
                  <div class="dropdown_budget_wrapper">
                    <div class="option_item" v-for="(item, key) in budgetByDay.list" :key="key">
                      <span>{{ item.text }}</span>
                      <a-input v-model:value="item.value" placeholder="若为空则为不限" @change="budgetByDay.handleTotal(record)" />
                    </div>
                  </div>
                </template>
              </a-select>
            </div>
          </a-input-group>
        </div>
      </template>
      <template v-else-if="column.title == '出价'">
        <a-input v-model:value="cjValue" :suffix="setCjSuffix(record)" placeholder="请输入出价"></a-input>
      </template>
      <template v-else-if="column.title == '深度出价'">
        <a-input v-model:value="text" placeholder="请输入深度出价"></a-input>
      </template>
      <template v-else-if="column.title == '投放日期'">
        <a-date-picker v-model:value="postDate.beginDate" v-if="postDate.checked" value-format="YYYY-MM-DD"></a-date-picker>
        <a-range-picker v-model:value="postDate.range" v-else value-format="YYYY-MM-DD"> </a-range-picker>
        <div style="margin: 10px 0">
          <a-checkbox v-model:checked="postDate.checked">长期投放(仅设置开始日期) </a-checkbox>
        </div>
      </template>
      <template v-else-if="column.title == '投放时间'">
        <a-radio-group v-model:value="updatePostDate" class="mb20">
          <a-radio value="noLimited">长投放</a-radio>
          <a-radio value="customize">具体时间</a-radio>
          <a-date-picker v-model:value="postDate.beginDate" v-if="updatePostDate == 'customize'" value-format="YYYY-MM-DD"></a-date-picker>
        </a-radio-group>
      </template>
      <!-- 底部按钮 -->
      <a-row type="flex" justify="center" class="mt10">
        <a-button @click="handleCancel" class="mr15">取消</a-button>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </a-row>
    </template>
    <span class="flexBetweenCenter">
      <slot>
        <span v-if="column.dataIndex == 'unit_bids'">{{ setBidColumn(record) }}</span>
        <span v-else :class="{ 'textHide-2rows': column.title.includes('名称') }">{{ text }}</span>
      </slot>
      <!-- 总计行和状态为已删除的不可编辑 -->
      <EditOutlined v-if="isEditAble(record)" @click="visible = true" />
    </span>
  </a-popover>
</template>

<script>
import { EditOutlined } from '@ant-design/icons-vue';
import { updateCampaignsNameKS, campaignUpdateDailyBudgetKS, updateUnitsBidAmountKS, updateUnitsNameKS, updateUnitsDeepbidKS, unitUpdateDailyBudgetKS, advertiserUpdateDailyBudgetKS, advertiserUpdateRemarkKS } from '@/api/popularize/clyq/index.js';
import columns from '@/views/popularize/assets/clyqColumns.js';
export default {
  name: 'EditableColumn',
  components: { EditOutlined },
  props: {},
  data() {
    return {
      record: this.$attrs.record,
      text: this.$attrs.text,
      index: this.$attrs.index,
      column: this.$attrs.column,
      editable: this.$attrs.editable,
      visible: false,
      cjValue: null,
      postDate: { checked: false, range: [], beginDate: '' }, //投放日期数据
      updateBudgetMode: 'BUDGET_MODE_INFINITE',
      updatePostDate: 'noLimited', // 投放时间
      activeKey: this.$attrs.activeKey,
      bidDictionary: columns.bidDictionary,
      // 磁力引擎分日预算
      budgetByDay: {
        list: [
          { value: undefined, text: '周一' },
          { value: undefined, text: '周二' },
          { value: undefined, text: '周三' },
          { value: undefined, text: '周四' },
          { value: undefined, text: '周五' },
          { value: undefined, text: '周六' },
          { value: undefined, text: '周日' },
        ],
        total: '', //下拉框回显数据
        dayBudgetSchedule: Array(7).fill(0), //最终分日预算数据
        handleTotal: record => {
          let str = '';
          this.budgetByDay.list.forEach((item, index) => {
            item.value && (str += `${item.text}:${item.value},`);
            this.budgetByDay.dayBudgetSchedule[index] = item.value || 0;
          });
          this.budgetByDay.total = str;
        },
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async handleOk() {
      let params = {};
      let res = {};
      const title = this.column.title;

      // 账户tab页下单独操作
      if (this.column.title == '账户预算') {
        let params = [];
        // 账户日预算请求数据换算成单位是厘!!!!!!!!!!!!!!!!!!!
        const record = {
          advertiserId: this.record.advertiser_id,
          number: Number((this.text + '')?.split(' 元')?.[0] || 0) * 1000,
          dayBudgetSchedule: this.budgetByDay.dayBudgetSchedule.map(item => Number(item) * 1000),
        };
        // 分日预算与预算不能同时传
        if (this.updateBudgetMode == 'BUDGET_MODE_SCHEDULE') {
          delete record.number;
        } else {
          delete record.dayBudgetSchedule;
        }
        params.push(record);
        res = await advertiserUpdateDailyBudgetKS(params);
      } else if (this.column.title == '账户备注') {
        params = [{ advertiserId: this.record.advertiser_id, remark: this.text }];
        res = await advertiserUpdateRemarkKS(params);
      }

      // 广告组tab页下单独操作
      else if (this.column.title == '广告组今日预算') {
        let params = [];
        const record = {
          unitId: this.record.unit_id,
          dailyBudget: this.dailyBudget || 0,
          dayBudgetSchedule: this.budgetByDay.dayBudgetSchedule,
        };
        // 分日预算与预算不能同时传
        if (this.updateBudgetMode == 'BUDGET_MODE_SCHEDULE') {
          delete record.dailyBudget;
        } else {
          delete record.dayBudgetSchedule;
        }
        params.push(record);
        res = await unitUpdateDailyBudgetKS(params);
      } else if (this.column.title == '广告组名称') {
        params = [{ unitId: this.record.unit_id, unitName: this.text }];
        res = await updateUnitsNameKS(params);
      } else if (this.column.title == '出价') {
        params = [{ unitId: this.record.unit_id, bid: this.cjValue }];
        res = await updateUnitsBidAmountKS(params);
      } else if (this.column.title == '深度出价') {
        params = [{ unitId: this.record.unit_id, deepConversionBid: parseFloat(this.text) }];
        res = await updateUnitsDeepbidKS(params);
      }

      // 广告计划tab页下单独操作
      else if (this.column.title == '计划名称') {
        params = [{ campaignId: this.record.campaign_id, campaignName: this.text }];
        res = await updateCampaignsNameKS(params);
      } else if (this.column.title == '计划今日预算') {
        let params = [];
        const record = {
          campaignId: this.record.campaign_id,
          budget: (this.dailyBudget + '')?.split(' 元')?.[0] || 0,
          dayBudgetSchedule: this.budgetByDay.dayBudgetSchedule,
        };
        // 分日预算与预算不能同时传
        if (this.updateBudgetMode == 'BUDGET_MODE_SCHEDULE') {
          delete record.budget;
        } else {
          delete record.dayBudgetSchedule;
        }
        params.push(record);
        res = await campaignUpdateDailyBudgetKS(params);
      }
      if (res.code == 0) {
        this.$message.success('编辑成功');
        this.text = this.$attrs.text;
      } else {
        const idList = {
          account: advertiser_id,
          campaign: campaign_id,
          ad: unit_id,
        };
        const errorId = this.record?.[idList[this.getActiveKey()]];
        let errorMsg = res?.data?.[0]?.[errorId];
        this.$message.error(res?.data?.[0]?.errorMsg || errorMsg);
        this.text = this.$attrs.text;
      }
      this.visible = false;
    },
    handleCancel() {
      this.record[this.column.dataIndex] = this.$attrs.text;
      this.text = this.$attrs.text;
      this.visible = false;
    },
    /** 设置出价展示
     * @param {*} record
     * @return {*}
     */
    setBidColumn(record) {
      const cjList = record.unit_bids?.split('|');
      if (cjList && cjList.length > 0) {
        const bidType = this.columnMap(this.bidDictionary.bid_type, cjList[0]);
        const bidNumber = cjList[1] == 0 ? cjList[2] : cjList[1];
        const ocpxActionType = this.columnMap(this.bidDictionary.ocpx_action_type, cjList[3]);
        const smartBidType = cjList[4];
        if (smartBidType == '1') {
          return `${bidType || ''} 自动出价${ocpxActionType ? '/' : ''}${ocpxActionType}` != '' ? `${bidType || '-'} 自动出价${ocpxActionType ? '/' : ''}${ocpxActionType}` : '-';
        } else {
          const bidShow = `${bidType || ''} ${bidNumber || 0} 元${ocpxActionType ? '/' : ''}${ocpxActionType}`;
          return bidShow != '' ? bidShow : '-';
        }
      } else {
        return '-';
      }
    },
    /** 设置出价编辑尾部提示
     * @param {*} record
     * @return {*}
     */
    setCjSuffix(record) {
      const cjList = record.unit_bids?.split('|');
      const ocpxActionType = cjList && cjList.length > 0 ? this.columnMap(this.bidDictionary.ocpx_action_type, cjList[3]) : '激活';
      return '元/' + ocpxActionType;
    },
    isEditAble(record) {
      const cjList = record.unit_bids?.split('|');
      const smartBidType = cjList && cjList.length > 0 ? cjList[4] : 'CUSTOM';
      if (!this.cjValue && cjList && cjList.length > 0) {
        this.cjValue = cjList[1] == 0 ? cjList[2] : cjList[1];
      }
      return this.editable && !(this.column.title == '广告出价' && smartBidType == 'SYSTEMATIC');
    },
    /** 遍历取值
     * @param {Array} columnOptions 遍历数据源
     * @param {String} columnValue 比较的数据
     * @param {String} key 比较的字段
     * @param {String} text 返回的字段
     * @return {*}
     */
    columnMap(columnOptions, columnValue, key = 'value', text = 'text') {
      if (columnValue) {
        const returnOpt = columnOptions?.filter(item => {
          return item[key] == columnValue;
        })?.[0];
        return returnOpt && returnOpt[text];
      }
    },
  },
};
</script>

<style scoped lang="less">
.yellow {
  color: rgb(245, 159, 112);
}
.grew {
  color: #999;
}
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
.deepPrice {
  width: 300px;
}
</style>
