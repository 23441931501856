<!--
 * @Author: LiuXin
 * @Date: 2021-10-12 11:10:59
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-28 16:25:02
-->
<template>
  <a-drawer v-bind="drawer" @close="closeDrawer" class="promotion_management_drawer">
    <template #title>
      {{ rowData[titleCode] }} <span v-if="titleCode == 'advertiser_name'">--{{ rowData.advertiser_id }}</span>
    </template>
    <a-tabs v-model:activeKey="activeKey" class="w100 h100 detail_tabs">
      <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.name">
        <a-spin :spinning="loading" class="w100 h100">
          <component :is="tab.component" :base-info="baseInfo" :row-data="rowData" ref="baseInfo"> </component>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
  <div :class="{ drawer_close_btn: true, hidden_btn: !visible }" @click="closeDrawer">
    <CloseOutlined />
  </div>
</template>

<script>
import BaseInfo from './components/BaseInfo.vue';
import BaseInfoForProductAndAccount from './components/BaseInfoForProductAndAccount.vue';
import CustomerCreativity from './components/CustomerCreativity.vue';
import ProgramCreativity from './components/ProgramCreativity.vue';
import TrendData from './components/TrendData.vue';
import TargetGroup from './components/TargetGroup.vue';
import OperationLog from './components/OperationLog.vue';
import columns from '../../assets/clyqColumns.js';
import echartsOptions from '../../assets/echartsOptions.js';
import { getBaseInfo } from '@/api/popularize/clyq/detail.js';
import { CloseOutlined } from '@ant-design/icons-vue';
export default {
  name: 'DetailDrawer',
  components: { CloseOutlined, TrendData, TargetGroup, BaseInfo, OperationLog, BaseInfoForProductAndAccount, CustomerCreativity, ProgramCreativity },
  emits: ['update:visible'],
  provide() {
    return { advertiseDetail: columns.advertiseDetail, echartsOptions };
  },
  inject: ['getActiveKey', 'getCreativityType'],
  props: {
    visible: { type: Boolean, default: false }, //抽屉显隐控制
    rowData: { type: Object, default: () => {} }, //点击详情的行数据
  },
  data() {
    return {
      parentTabKey: undefined,
      activeKey: 'baseInfo',
      baseInfo: {},
      loading: false,
    };
  },
  computed: {
    // 抽屉配置
    drawer() {
      return { visible: this.visible, mask: false, width: 1080 };
    },
    // tab标签配置
    tabs() {
      const key = this.getActiveKey();
      const tabs = {
        product: [
          { name: '基础信息', key: 'baseInfo', component: 'BaseInfoForProductAndAccount' },
          // { name: '趋势数据', key: 'trendData', component: 'TrendData' },
          // { name: '受众人群', key: 'targetGroup', component: 'TargetGroup' },
          // { name: '操作日志', key: 'operationLog', component: 'OperationLog' },
        ],
        account: [
          { name: '基础信息', key: 'baseInfo', component: 'BaseInfoForProductAndAccount' },
          // { name: '趋势数据', key: 'trendData', component: 'TrendData' },
          // { name: '受众人群', key: 'targetGroup', component: 'TargetGroup' },
          // { name: '操作日志', key: 'operationLog', component: 'OperationLog' },
        ],
        campaign: [
          { name: '基础信息', key: 'baseInfo', component: 'BaseInfoForProductAndAccount' },
          // { name: '趋势数据', key: 'trendData', component: 'TrendData' },
          // { name: '受众人群', key: 'targetGroup', component: 'TargetGroup' },
          // { name: '操作日志', key: 'operationLog', component: 'OperationLog' },
        ],
        ad: [
          { name: '基础信息', key: 'baseInfo', component: 'BaseInfo' },
          // { name: '趋势数据', key: 'trendData', component: 'TrendData' },
          // { name: '受众人群', key: 'targetGroup', component: 'TargetGroup' },
          // { name: '操作日志', key: 'operationLog', component: 'OperationLog' },
        ],
        creativity: [
          { name: '基础信息', key: 'baseInfo', component: this.getCreativityType() == 'PROGRAM' ? 'ProgramCreativity' : 'CustomerCreativity' },
          // { name: '趋势数据', key: 'trendData', component: 'TrendData' },
          // { name: '受众人群', key: 'targetGroup', component: 'TargetGroup' },
          // { name: '操作日志', key: 'operationLog', component: 'OperationLog' },
        ],
      };
      return tabs[key];
    },
    titleCode() {
      const key = this.getActiveKey();
      const titleCodes = {
        product: 'product_name',
        account: 'advertiser_name',
        campaign: 'campaign_name',
        ad: 'unit_name',
        creativity: 'creative_name',
      };
      return titleCodes[key];
    },
  },
  watch: {
    rowData: {
      handler(val1) {
        // 避免切换tab页之后点击详情而影响上一个tab页所属的详情页
        if (this.parentTabKey == this.getActiveKey()) {
          this.getBaseInfo();
        }
      },
      // deep: true,
    },
  },
  mounted() {
    this.parentTabKey = this.getActiveKey();
  },
  methods: {
    closeDrawer() {
      this.$refs.baseInfo.beforeDrawerClosed?.();
      this.$emit('update:visible', false);
    },
    // 获取基本信息
    async getBaseInfo() {
      const type = this.getActiveKey();
      const idDict = {
        product: this.rowData.productId,
        account: this.rowData.advertiser_id,
        campaign: this.rowData.campaign_id,
        ad: this.rowData.unit_id,
        creativity: this.rowData.creative_id,
      };
      try {
        this.loading = true;
        let id = idDict[type];
        const creativityType = this.getCreativityType();
        if (id) {
          const res = await getBaseInfo({ type, id, creativityType });
          this.baseInfo = res.data || {};
        } else {
          this.$message.warn('缺少相关信息，请刷新后重试！');
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
