<!--
 * @Author: LiuXin
 * @Date: 2021-10-13 15:29:39
 * @LastEditors: LiuXin
 * @LastEditTime: 2021-12-23 16:49:14
-->
<template>
  <div class="base_info_wrapper base-info-width-img">
    <div class="img_wrapper">
      <template v-if="imgList.length">
        <CreativeBox v-for="item in imgList" :key="item.url" v-bind="{ imgInfo: item, status: systemStatusDict[item.configuredStatus] }" class="mb10" />
      </template>
      <a-empty v-else></a-empty>
    </div>

    <div class="baseinfo_text">
      <div class="baseInfo_group" v-for="group in groupList" :key="group.groupName">
        <p class="module_title w100">
          <span class="title_content"> {{ group.groupName }} </span>
        </p>
        <div class="info_item mb10" v-for="groupItem in group.groupItemList" :key="groupItem.label">
          <div class="info_label">{{ groupItem.label }}：</div>
          <div class="info_value" v-if="groupItem.label == '分日预算'">
            <template v-if="groupItem.value?.length">
              <p v-for="item in groupItem.value" :key="item">{{ item }}</p>
            </template>
            <template v-else>- </template>
          </div>
          <div class="info_value" v-else>{{ groupItem.value ?? '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreativeBox from '@/views/popularize/components/CreativeBox.vue';
export default {
  emits: ['editAdGroup'],
  components: { CreativeBox },
  inject: ['getActiveKey', 'clyqDict'],
  props: {
    baseInfo: { type: Object, default: () => {} },
  },
  data() {
    const siteDict = {};
    JSON.parse(localStorage.getItem('promoteCodesOptionsKS') || '[]').forEach(item => {
      siteDict[item.value] = item.label;
    });
    return {
      siteDict,
    };
  },
  computed: {
    groupList() {
      return [
        {
          groupName: '基本情况',
          groupItemList: [
            { label: '广告组ID', value: this.baseInfo.unitId },
            { label: '广告组名称', value: this.baseInfo.unitName },
            { label: '广告组状态', value: this.clyqDict.adStatus(this.baseInfo.status) },
            { label: '所属广告计划', value: this.baseInfo.campaignId },
          ],
        },
        {
          groupName: '推广内容',
          groupItemList: [
            { label: '推广目标', value: this.baseInfo.campaign_type },
            { label: '下载链接', value: this.baseInfo.url },
            { label: '链接类型', value: this.clyqDict.urlType(this.baseInfo.urlType) },
            { label: '应用名称', value: this.baseInfo.diverseData?.app_name },
            { label: '转化目标', value: this.baseInfo.convertId == 0 ? '-' : this.baseInfo.convertId },
          ],
        },
        {
          groupName: '预算和排期',
          groupItemList: [
            { label: '单日预算', value: this.baseInfo.dayBudget == 0 ? '不限' : this.baseInfo.dayBudget },
            { label: '分日预算', value: this.baseInfo.dayBudgetSchedule },
            { label: '排期', value: `${this.baseInfo.beginTime || '-'}-${this.baseInfo.endTime || ''}` },
            { label: '投放方式', value: this.clyqDict.speed(this.baseInfo.speed) },
            { label: '投放时段', value: this.baseInfo.schedule?.length || '-' },
            { label: '创意展现方式', value: this.clyqDict.showMode(this.baseInfo.showMode) },
          ],
        },
        {
          groupName: '优化目标和计费',
          groupItemList: [
            { label: '优化目标', value: this.clyqDict.ocpxActionType(this.baseInfo.ocpxActionType) },
            { label: '出价', value: `${this.clyqDict.bidType(this.baseInfo.bidType)} ${this.baseInfo.bid || this.baseInfo.cpaBid || this.baseInfo.smartBid} 元/${this.clyqDict.ocpxActionType(this.baseInfo.ocpxActionType)}` },
            { label: '深度出价', value: this.clyqDict.deepConversionType(this.baseInfo.deepConversionType) == '-' ? '-' : `${this.baseInfo.deepConversionBid} 元/${this.clyqDict.deepConversionType(this.baseInfo.deepConversionType)}` },
            { label: 'ROI系数', value: this.baseInfo.roiRatio },
          ],
        },
        {
          groupName: '目标人群',
          groupItemList: [
            { label: '定向', value: this.baseInfo.target },
            { label: '自动生成视频', value: this.baseInfo.autoCreatePhoto == true ? '是' : '否' },
            // { label: '自定义人群', value: this.baseInfo.key },
            // { label: '地区', value: this.baseInfo.regionCategoryNames },
            // { label: '年龄.', value: this.baseInfo.ages_range },
            // { label: '性别', value: this.baseInfo.gender },
            // { label: '设备品牌', value: this.baseInfo.device_brand },
            // { label: '设备价格', value: this.baseInfo.device_price },
            // { label: '行为定向', value: this.baseInfo.behavior },
            // { label: '意向定向', value: this.baseInfo.interest },
            // { label: 'APP行为', value: this.baseInfo.app_interest },
            // { label: '商业兴趣', value: this.baseInfo.business_interest_type },
            // { label: '网络环境', value: this.baseInfo.network },
            // { label: '网红粉丝', value: this.baseInfo.fans_star },
            // { label: '过滤已转化', value: this.baseInfo.filter_converted_level },
            // { label: '智能扩量', value: this.baseInfo.intelli_extend },
          ],
        },
      ];
    },
    imgList() {
      const imgList = [];
      if (this.baseInfo.adcreative?.length) {
        this.baseInfo.adcreative.forEach(adcreative => {
          adcreative.siteSet = adcreative.siteSet
            ?.split(',')
            ?.map(item => this.siteDict[item])
            ?.join(',');
          if (Array.isArray(adcreative.previewUrl)) {
            adcreative.previewUrl.forEach(url => {
              url && imgList.push({ ...adcreative, url });
            });
          } else {
            adcreative.previewUrl && imgList.push({ ...adcreative, url: adcreative.previewUrl });
          }
        });
      }
      return imgList;
    },
  },
  methods: {
    setInventoryType(obj) {
      let inventoryType = '';
      if (obj.value && Array.isArray(obj.value)) {
        obj.value.forEach((item, index) => {
          inventoryType += (this.clyqDict.inventoryType(item) || '-') + (index == obj.value.length - 1 ? '' : ',');
        });
      }
      return inventoryType;
    },
  },
};
</script>

<style lang="less" scoped>
.editBudget {
  width: 300px;
  .suggestion {
    margin: 10px 0;
    color: #999;
  }
}
</style>>